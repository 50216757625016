<template>
  <div>
    <div id="parl"></div>
    <div id="banner" class="pl-2">
        <h1 id="h1Content1">"Wij leveren enkel en alleen gecontroleerde, gebruikte banden in A1-kwaliteit, met garantie<sup>*</sup>"</h1>
    </div>

    <div class="pl-2" id="about">
    <h1>Over ons</h1>
        <p>Al onze banden zijn visueel en technisch gecontroleerd en uitsluited van A1-kwaliteit. Afgekeurde, opgesneden of gerepareerde banden worden door ons niet geleverd. Wij kopen dan ook absoluut geen gebruikte banden in van sloop- of schadeauto’s.</p>
        <p>Circa <i>2.000</i> gebruikte kwaliteitsautobanden zijn uit voorraad leverbaar. De door ons gevoerde banden hebben een profieldiepte van meer dan 80% van de profieldiepte van een nieuwe band.</p>
        <p>Wanneer u twijfelt of u nieuwe banden nodig heeft,bijvoorbeeld of ze nog aan de APK-eisen voldoen geven wij ook geheel vrijblijvende en deskundig advies over uw banden. Wij denken altijd met u mee en zullen u altijd objectief en eerlijk adviseren.</p>
        <p>De banden worden door ons vakkundig gemonteerd en gebalanceerd. Voor reparatie van autobanden kunt u uiteraard ook bij ons terecht, evenals voor de levering van nieuwe banden tegen zeer concurrerende All-in prijzen. Wij zullen u hierbij geheel vrijblijvend informeren en adviseren.</p>
        <p>De prijzen zijn afhankelijk van de door u gewenste maat. Wij hanteren concurrerende All-in prijzen, (de prijzen zijn inclusief.: de- en montage, ventiel(-en), verwijderingsbijdrage, balanceren en b.t.w.). U wordt bij het afrekenen dus niet geconfronteerd met "een verrassing" in de vorm van een hoger te betalen bedrag als gevolg van "bijkomende kosten" voor werkzaamheden en materialen welke vooraf niet bij u waren gemeld.</p>
    </div>

    <div id="stock" class="pl-2">
        <h1 class="py-2">Banden</h1>
            <v-row class="mr-2">
            <v-col
            cols="12"
            md="6">
                <v-card style="background-color: #1F1F1F; color: white !important" elevation="10">
                    <v-card-title>Winterbanden</v-card-title>
                    <v-divider />
                    <v-card-text style="color: white !important">
                        &#8901; Bij temperaturen <strong>lager</strong> dan 7 &#176;C (kan al voorkomen in de herfst).
                        <br>
                        &#8901; Soepel bij temperaturen <strong>onder</strong> 7 &#176;C, waar zomerband dan harder wordt en dus grip verliest.
                        <br>
                        &#8901; aangepast profiel heeft u meer ijs-, sneeuw- en waterafvoer en zodoende minder kans op aquaplaning.
                        <br>
                        &#8901; Door de vele insnijdingen heeft u meer grip op besneeuwde wegen en op wegen met ijsafzetting.
                        <br>
                        &#8901; In Duitsland, Frankrijk en Luxemburg is de winterband verplicht gesteld bij winterse omstandigheden.
                        <br>
                        &#8901; Indien u bij winterse omstandigheden met zomerbanden rijdt en de politie constateert dit dan wordt proces-verbaal opgemaakt, waarnaast ook een verbod opgelegd kan worden verder te rijden. 
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
            cols="12"
            md="6"
            >
                <v-card style="background-color: #1F1F1F; color: white !important" elevation="10">
                    <v-card-title>Zomerbanden</v-card-title>
                    <v-divider />
                    <v-card-text style="color: white !important">
                        &#8901; Bij temperaturen <strong>hoger</strong> dan 7 &#176;C.
                        <br>
                        &#8901; Soepelheid voor goede grip.
                        <br>
                        &#8901; Goed profiel zorgt voor een goede waterafvoer en daardoor geringe kans op aquaplaning.
                        <br>
                        &#8901; Minder lamellen (insnijdingen) dan een winterband, betere wegliggen en optimaal rijcomfort.
                        <br>
                        &#8901; Lage rolweerstand geeft een lager brandstofverbruik.
                        <br>
                        &#8901; Uitermate geschikt voor allerlei soorten wegen.
                        <br>
                        <br>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>

    <div id="reviews">
        <h1 class="pl-2" style="color: black">Reviews</h1>
        <v-row class="mx-2">
            <v-col cols="5" lg="3" v-for="review in reviews" :key="review.index">
                <v-card color="#1F1F1F" dark style="min-height: 250px !important;" elevation="10">
                    <v-card-title class="headline">
                      {{review.title}}
                    </v-card-title>
    
                    <v-card-subtitle><p class="mb-6">{{review.desc}}</p></v-card-subtitle>
    
                    <v-card-actions style="position: absolute; bottom: 0">
                      <v-rating :dense="true" half-increments background-color="warning lighten-1" color="warning" readonly size="18" :value="review.rating"></v-rating>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>

    <div id="brands">
        <marquee-text
          :duration="100"
          :repeat="3"
          class="py-2 bg-dark text-white"
        >
          <img class="mx-3 brand" src="../assets/img/logos/bridgestone.png" height="80" alt="BRIDGESTONE">
          <img class="mx-3 brand" src="../assets/img/logos/continental.png" height="80" alt="CONTINENTAL">
          <img class="mx-3 brand" src="../assets/img/logos/goodyear.png" height="80" alt="GOODYEAR">
          <img class="mx-3 brand" src="../assets/img/logos/michelin.png" height="80" alt="MICHELIN">
          <img class="mx-3 brand" src="../assets/img/logos/pirelli.png" height="80" alt="PIRELLI">
          <img class="mx-3 brand" src="../assets/img/logos/Hankook.png" height="80" alt="HANKOOK">
          <img class="mx-3 brand" src="../assets/img/logos/dunlop.png" height="80" alt="DUNLOP">
          <img class="mx-3 brand" src="../assets/img/logos/kumho.png" height="80" alt="KUMHO">
          <img class="mx-3 brand" src="../assets/img/logos/nokian.jpg" height="80" alt="NOKIAN">
          <img class="mx-3 brand" src="../assets/img/logos/uniroyal.jpg" height="80" alt="UNIROYAL">
          <img class="mx-3 brand" src="../assets/img/logos/Vredestein.png" height="80" alt="VREDESTEIN">
            <img class="mx-3 brand" src="../assets/img/logos/yokohama.png" height="80" alt="YOKOHAMA">
        </marquee-text>
    </div>
  </div>
</template>

<script>
export default {
    data: () => ({
        reviews: [
        {title: "Snelle service en goeie voorraad!", desc: "Door een maat van me in kennis gekomen met de hedelse banden service. En heb er ook een snelle service gehad. Het zijn vriendelijke mensen. En hebbe goede banden op voorraad. Ik zou iedereen het ook aanraden om er langs te gaan als je nieuwe banden nodig heb.", rating: 5},
        {title: "Fantastische service!", desc: "Afgelopen weekend 4 nieuwe banden erop laten zetten voor een super prijs. Meteen geholpen! Zeer gastvrij en goede snelle service! Auto zweeft weer over de weg !Raad deze garage bij iedereen aan", rating: 4.5},
        {title: "Reserve band", desc: "Reed rond met en reserve band waar je 80 mee kon rijden veel te gevaarlijk er moest en oplossing komen en andere maat eronder gezet wat kon voor tijdelijk en de nieuwe besteld nu kan ik toch overal nog komen !", rating: 4},
        {title: "Gezellige & goeie service!", desc: "Heele goede service, gezellige en aardige mensen prijs is erg goed zeker de moeite waard om eens langs te schiete", rating: 4}
        ],
        stock: 0,
    }),
}
</script>

<style>
#icon{
    position: absolute;
    top: 70vh;
    color: white;
    font-size: 50px;
    margin-left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

#banner{
    background-color: #193B85;
    margin-top: -10px;
    position: relative;
    -webkit-box-shadow: 0px 10px 25px -4px rgba(0,0,0,0.77);
    -moz-box-shadow: 0px 10px 25px -4px rgba(0,0,0,0.77);
    box-shadow: 0px 10px 25px -4px rgba(0,0,0,0.77);
}

#brands{
    color: black;
}

.review{
    min-height: 200px !important;
}

#about{
    color: black;
}

#h1Content1{
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
}

#parl{
  /* The image used */
  background-image: url("../assets/img/header.jpeg");

  /* Set a specific height */
  height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#stock{
    background-color: #193B85;

}
</style>